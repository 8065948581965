<template>
  <div style="min-height:100vh;">
    <titleLink title="安全防护" :onClick="onClickBack"></titleLink>
    <div style="padding:0.3rem .2rem 0 .2rem;height:95vh;overflow-y:hidden;overflow:scroll">
      <van-pull-refresh v-model="loading" @refresh="onRefresh" style="padding-bottom:2rem">
        <van-list v-model="listLoading" 
          :finished="pagination.next_page == null" 
          :finished-text="notifications.length == 0? '': '没有更多了'" @load="onListLoading">
          <card v-for="item, idx in notifications" :key="idx"
            :style="{'background': item.relation.is_read? '#f1f1f1': '#fff', 'margin-top': '.3rem'}" 
            @click.native="toInfoPage(item.relation.id)">
            <template #content>
              <van-row>
                <van-col span="12" class="title_col">
                  <span :class="item.relation.is_read? 'tab-item-title-isread':
                    'tab-item-title'">
                    {{item.notification.title}}
                  </span>
                </van-col>
                <van-col span="12" 
                  style="text-align:right" 
                  class="title_col">
                  <span class="breif_text">
                    {{item.notification.created_at}}
                  </span>
                </van-col>
                <van-col span="24">
                  <div class="ellipsis">
                    <span class="breif_text" >
                      {{item.notification.breif}}
                    </span>
                  </div>
                </van-col>
                <van-col span="24">
                  <van-image :src="item.notification.file_url" 
                    v-if="item.notification.file_url != null"
                    radius="10" style="margin-top:.2rem;height:3rem;width:100%" fit="cover">
                  </van-image>
                  <div v-if="item.notification.file_url == null && item.notification.content == null"
                    style="height:.2rem">&nbsp;</div>
                </van-col>
              </van-row>
              <div v-if="!item.relation.is_read" class="dot"/>
            </template>
          </card>
        </van-list>
      </van-pull-refresh>
      <van-empty v-if="notifications.length == 0" description="没有信息" style="margin-top:.2rem"/>
    </div>
  </div>
</template>

<script>
import card from "@/views/components/Card";
import titleLink from "@/views/components/TitleLink"
import {mapGetters} from "vuex"
export default {
  components: {
    card, titleLink
  },
  data() {
    return {
      loading: false,
      listLoading: false,
      pagination: {
        current_page: 0,
        next_page: null,
        total_count: 0,
        total_pages: 0
      }
    }
  },
  computed: {
    ...mapGetters('notification', {
      notifications: "getAllNotifications"
    })
  },
  created() {
    this.onRefresh()
  },
  methods: {
    onClickBack() {
      this.$router.push("/home");
    },
    toInfoPage(id) {
      this.$router.push(`info/${id}`)
    },
    onRefresh() {
      const _self = this
      this.$store.dispatch('notification/getAllNotifications', 
        {type: 4, page:1}).then((res) => {
        if(res.status === 200) {
          _self.loading = false
          _self.fillPagination(res)
        }
      })
    },
    onListLoading() {
      const _self = this
      this.$store.dispatch('notification/getAllNotifications', 
        {type: 4, page:this.pagination.next_page}).then((res) => {
        if(res.status === 200) {
          _self.listLoading = false
          _self.$store.dispatch('notification/syncNotifications', res.data)
          _self.fillPagination(res)
        }
      })
    },
    fillPagination(res) {
      this.pagination.current_page = res.current_page
      this.pagination.total_count = res.total_count
      this.pagination.next_page = res.next_page
      this.pagination.total_pages = res.total_pages
    }
  }
}
</script>

<style scoped>
.title_col {
  height:.2rem;
  line-height: .2rem;
}
.breif_text {
  font-size: 12px;
  color: #666666;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
  font-weight: 100;
  color: #666666;
}
.dot {
  position: absolute;
  height: 8px;
  width: 8px;
  background: #f04134;
  border-radius: 50%;
  top: 0.2rem;
  right: 0.1rem;
}
.tab-item-title-isread {
  font-weight: 600;
  font-size: 12px;
  color: #808080;
}
.tab-item-title {
  font-weight: bold;
  font-size: 12px;
}
</style>